.edit-dialog {
    width: 600px;
}

mat-form-field.product-name, mat-form-field.quantity {
    width: 100%;
}

.price-options {
    padding-bottom: 12px;
}

.invisible {
    visibility: hidden;
}

.mat-select-trigger {
    height: auto !important;
}

.mat-select-placeholder {
    font-size: 15px !important;
    width: 100% !important;
}

mat-form-field {
    padding: 0 2px;
}
